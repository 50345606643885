import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import moment from 'moment'
import './Footer.scss'
// import mbawa from '../../images/accr-mbawa.svg';
// import gbca from '../../images/accr-gbca.svg';
// import dia from '../../images/accr-dia.svg';
// import iso from '../../images/accr-iso-new.svg';
// import pca from '../../images/pca.jpg';
// import aha from '../../images/aha.jpg';

class FooterTemplate extends Component {
  render() {
    const { data, location = {} } = this.props
    const { pathname = null } = location
    const { siteSettings, site } = data
    const { title: siteName } = site
    const { options } = siteSettings
    const { socialLinks } = options
    const isHomePage = Boolean(pathname === '/')
    return (
      <footer className="footer" role="contentinfo">
        <div className="wrap">
          <div className="copy-container">
            <div className="copy-flex-wrap">
              <p className="copyright">
                <span>&copy; </span>
                <span> Bellfort </span>
                <span>{moment().format('YYYY')}</span>
              </p>
              <span className="credit">
                Website by&nbsp;
                {isHomePage ? (
                  <a href="https://distl.com.au/">Distl</a>
                ) : (
                  <span>Distl</span>
                )}
              </span>
            </div>
          </div>
          <div className="social-links">
            <ul className="links">
              {socialLinks &&
                socialLinks.map((link, index) => {
                  return (
                    <li className="link" key={index}>
                      <a
                        href={link.url}
                        className={`inner-link ${link.type}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {link.type}
                      </a>
                    </li>
                  )
                })}
            </ul>
          </div>

          {/* <div className="accreditations">
            <ul className="accreditations-list">
              <li className="accreditation mwaba">
                <span className="inner-image" style={{backgroundImage: `url('${mbawa}')`}} />
              </li>
              <li className="accreditation gbca">
                <span className="inner-image" style={{backgroundImage: `url('${gbca}')`}} />
              </li>
              <li className="accreditation dia">
                <span className="inner-image" style={{backgroundImage: `url('${dia}')`}} />
              </li>
              <li className="accreditation iso">
                <span className="inner-image" style={{backgroundImage: `url('${iso}')`}} />
              </li>
              <li className="accreditation pca">
                <span className="inner-image" style={{backgroundImage: `url('${pca}')`}} />
              </li>
              <li className="accreditation aha">
                <span className="inner-image" style={{backgroundImage: `url('${aha}')`}} />
              </li>
            </ul>
          </div> */}
        </div>
      </footer>
    )
  }
}

export const Footer = props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            socialLinks {
              type
              url
            }
          }
        }
        metaData: wordpressSiteMetadata {
          url
        }
      }
    `}
    render={data => <FooterTemplate data={data} {...props} />}
  />
)
